export const AppConfig = {
    firebase: {
        projectId: 'elpaso-paxassist-qa',
        appId: '1:461451123908:web:00f3adee7a6106081bfd91',
        databaseURL: 'https://elpaso-paxassist-qa-default-rtdb.firebaseio.com',
        storageBucket: 'elpaso-paxassist-qa.appspot.com',
        locationId: 'us-central',
        apiKey: 'AIzaSyCW2HGzKr_ppn9Q-KBz2tPJijGH0IYSjbw',
        authDomain: 'elpaso-paxassist-qa.firebaseapp.com',
        messagingSenderId: '461451123908',
        measurementId: 'G-F935GXR992',
    },
    production: false,
    defaultKioskLocation: '00-KIOSK-L1',
    environment: 'ELP_MICROSITE_QA',
    flavor: 'ELP',
    appType: 'microsite',
    endpoints: {
        url: 'https://elp-api-qa.itxi.aero',
        apiKey: 'be8ef554-dd22-45ee-8cef-7664174e66e0',
    },

    getContentInterval: 600000,
    airportZone: 'America/Denver',
    weather: {
        API: 'https://api.weather.gov/gridpoints/EPZ/103,56/forecast/hourly',
        refreshInterval: 600000,
    },
    appLanguages: ['en', 'es'],
    micrositeUrl: 'https://elpaso-companion-qa.itxi.aero/',
};
