import { UtilityService } from '@app/services/utility/utility.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ApiService } from '@app/services/api/api.service';
import { Injectable } from '@angular/core';
import { types } from '../actions';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DataBusEffects {
    getWeather$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(types.GET_WEATHER),
            mergeMap(() =>
                this.api.getWeatherInformation().pipe(
                    map((weather) => {
                        return {
                            type: types.GET_WEATHER_SUCCESS,
                            payload: weather,
                        };
                    }),
                    catchError(() =>
                        of({
                            type: types.GET_WEATHER_FAILED,
                        })
                    )
                )
            )
        );
    });

    getContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(types.GET_CONTENT_ATTEMPT),
            mergeMap(() =>
                this.api.getContent().pipe(
                    map((content) => {
                        return {
                            type: types.GET_CONTENT_SUCCESS,
                            payload: {
                                ...content.data,
                                cfVersion: content?.version,
                            },
                        };
                    }),
                    catchError(() =>
                        of({
                            type: types.GET_CONTENT_FAILED,
                        })
                    )
                )
            )
        );
    });

    getFlights$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(types.GET_FLIGHTS_ATTEMPT),
            mergeMap(() =>
                this.api.getFlights().pipe(
                    map((data) => {
                        return {
                            type: types.GET_FLIGHTS_SUCCESS,
                            payload: this.utilityService.restructureFlights(
                                data.flights
                            ),
                        };
                    }),
                    catchError(() =>
                        of({
                            type: types.GET_FLIGHTS_FAILED,
                        })
                    )
                )
            )
        );
    });

    constructor(
        private utilityService: UtilityService,
        private actions$: Actions,
        private api: ApiService
    ) {}
}
