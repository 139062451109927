import { TimeConversionService } from '@app/services/time-conversion/time-conversion.service';
import { REVISION, VERSION } from 'environments/version';
import { TranslateService } from '@ngx-translate/core';
import { selectDataBus } from '@app/store/selectors';
import { AppConfig } from 'environments/environment';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-copyright',
    templateUrl: './copyright.component.html',
    styleUrls: ['./copyright.component.scss'],
})
export class CopyrightComponent implements OnDestroy {
    currentYear: string =
        this.timeConversion.formatDate('YYYY', new Date(), true) === '2022'
            ? '2022'
            : `2022${this.timeConversion.formatDate(
                  '-YYYY',
                  new Date(),
                  true
              )}`;
    title = `${this.translate.instant(
        `MAIN.${AppConfig.environment}`
    )} v${VERSION} (${REVISION})`;
    subTitle = `© SITA ${this.currentYear}.`;
    cloudFunctionVersion: string;
    dataBusSubscription: any;

    constructor(
        private timeConversion: TimeConversionService,
        private translate: TranslateService,
        private store: Store
    ) {
        const dataBusState$ = this.store.select(selectDataBus);

        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.cloudFunctionVersion = dataBusItem.cfVersion;
        });
    }

    ngOnDestroy(): void {
        this.dataBusSubscription?.unsubscribe();
    }
}
