/* eslint-disable @ngrx/on-function-explicit-return-type */
import { DataBusStateModel } from '@app/models/store/data-bus';
import { ContentModel } from '@app/models/store/content';
import { Action, createReducer, on } from '@ngrx/store';
import {
    getWeatherInformationSuccess,
    getContentSuccess,
    getFlightsSuccess,
    setPinCoordinates,
    setKioskLocation,
    setIsGetContent,
    changeLanguage,
    setPOIWalkTime,
    setImages,
} from '../actions';
import { AppConfig } from 'environments/environment';

const initialState: DataBusStateModel = new DataBusStateModel({});

const dataBusReducer = createReducer(
    initialState,
    on(getContentSuccess, (state, action) => {
        const _d = { ...state };

        _d.content = new ContentModel({
            ...action.payload,
            isGetContent: true,
        });
        _d.cfVersion = action.payload.cfVersion || '';
        return _d;
    }),
    on(getFlightsSuccess, (state, action) => {
        const _d = { ...state };

        _d.flights = action.payload.flights;
        _d.airlinesCodes = action.payload.airlinesCodes;

        return _d;
    }),
    on(getWeatherInformationSuccess, (state, action): any => {
        const _d = { ...state };
        let icon = action.payload.properties.periods[0].icon;
        icon = icon.split('/');

        _d.weather = {
            temperature: action.payload.properties.periods[0].temperature,
            weatherImage: {
                mode: icon[icon.length - 2],
                key: icon[icon.length - 1].split('?')[0],
            },
        };

        return _d;
    }),
    on(setPOIWalkTime, (state, action) => {
        const _d = { ...state };

        _d.mapCategories = action.payload;
        return _d;
    }),
    on(changeLanguage, (state, action) => {
        const _d = {
            ...state,
            language: action.payload,
        };
        return _d;
    }),
    on(setImages, (state, action) => {
        const _d = { ...state, images: action.payload };

        return _d;
    }),
    on(setIsGetContent, (state, action) => {
        const _d = { ...state };

        _d.content = new ContentModel({
            ..._d.content,
            isGetContent: action.payload,
        });

        return _d;
    }),
    on(setKioskLocation, (state, action) => {
        const _d = { ...state };

        _d.kioskLocation =
            action.payload ||
            _d.kioskLocation ||
            AppConfig.defaultKioskLocation;

        return _d;
    }),
    on(setPinCoordinates, (state, action) => {
        const _d = { ...state };

        _d.pinCoordinates = action.position || _d.pinCoordinates;
        _d.kioskFloor = action.floor || _d.kioskFloor;

        return _d;
    })
);

export default function reducer(
    state: DataBusStateModel,
    action: Action
): DataBusStateModel {
    return dataBusReducer(state, action);
}
