import { AppConfig } from 'environments/environment';
import { ButtonModel } from '@app/models/button';

export const headerActions: ButtonModel[] = [
    {
        id: 'SEARCH',
        icon: {
            icon: 'search',
            containerStyle: {
                width: '1.2rem',
                height: '1.2rem',
            },
        },
        containerStyle: {
            backgroundColor: 'var(--secondary-bg-color)',
            padding: '0',
            minWidth: '3rem',
            borderRadius: '12px',
            minHeight: '2.5rem',
        },
    },
    {
        id: 'LANGUAGE',
        toggle: true,
        text: AppConfig.appLanguages[0],
        toggleText: AppConfig.appLanguages[1],
        containerStyle: {
            backgroundColor: 'var(--secondary-bg-color)',
            padding: '0',
            minWidth: '3rem',
            borderRadius: '12px',
            border: 0,
            minHeight: '2.5rem',
        },
    },
];

export const categoriesHeaderActions: ButtonModel[] = [
    {
        id: 'CATEGORY',
        toggle: true,
        icon: {
            icon: 'map',
            containerStyle: {
                width: '1.2rem',
                height: '1.2rem',
            },
        },
        toggleIcon: {
            icon: 'dashboard',
            containerStyle: {
                width: '1.2rem',
                height: '1.2rem',
            },
        },
        containerStyle: {
            backgroundColor: 'var(--secondary-bg-color)',
            padding: '0',
            minWidth: '3rem',
            borderRadius: '12px',
            border: 0,
            minHeight: '2.5rem',
        },
    },
    ...headerActions,
];

export const backButtonProps = {
    id: 'BACK',
    icon: {
        icon: 'back',
        containerStyle: {
            width: '1.2rem',
            height: '1.2rem',
        },
    },
    containerStyle: {
        backgroundColor: 'var(--secondary-bg-color)',
        padding: '0',
        minWidth: '2.75rem',
        borderRadius: '12px',
        minHeight: '2.75rem',
    },
};

export const listIconStyle = {
    filter: 'brightness(0) invert(1)',
    borderRadius: '8px',
    padding: '1rem',
    height: '3rem',
    width: '3rem',
};

export const containerIconStyle = {
    height: 'fit-content',
    borderRadius: '8px',
    width: '100%',
};
