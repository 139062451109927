import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Modules
import { ColorsRoutingModule } from '@app/shared/pages/colors/colors-routing.module';
import { MicrositeRoutingModule } from '@app/microsite/microsite-routing.module';

const routes: Routes = [
    {
        path: 'colors',
        loadChildren: () =>
            import('@app/shared/pages/colors/colors.module').then(
                (m) => m.ColorsModule
            ),
    },
    {
        path: 'microsite',
        loadChildren: () =>
            import('@app/microsite/microsite.module').then(
                (m) => m.MicrositeModule
            ),
    },
    {
        path: '',
        redirectTo: 'microsite',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: 'microsite',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        MicrositeRoutingModule,
        ColorsRoutingModule,
    ],
    exports: [RouterModule],
})
export class MainRoutingModule {}
