import { ListItemModel } from '@app/models/list-item';
import {
    EventEmitter,
    OnChanges,
    Component,
    OnInit,
    Output,
    Input,
} from '@angular/core';

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit, OnChanges {
    @Output() listItemClicked = new EventEmitter();

    @Input() showCategoryIcon = false;
    @Input() showPoiDetails = false;
    @Input() props: ListItemModel;
    @Input() param = '';

    isPoiClosed: boolean;
    isPoiOpen: boolean;
    showRating = false;

    ngOnInit(): void {
        this.initializeProps();

        if (this.param && this.param === this.props.id) {
            setTimeout(() => {
                this.onListItemClick(this.props);
            }, 200);
        }
    }

    ngOnChanges(): void {
        this.initializeProps();
    }

    onListItemClick(event: ListItemModel): void {
        this.listItemClicked.emit(event);
    }

    initializeProps(): void {
        this.props = new ListItemModel(this.props);

        const state = this.props.moreInfo?.find(
            (item) => item.id === 'STATE'
        )?.text;

        this.isPoiOpen = state?.includes('OPEN');
        this.isPoiClosed = state?.includes('CLOSE');
    }

    openLinkInNewTab(link?: string): void {
        window.open(
            link ||
                `https://www.google.com.sa/maps/search/${this.props.location}?hl=en`,
            '_blank'
        );
    }
}
