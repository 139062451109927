export const types = {
    //DataBus
    GET_CONTENT_SUCCESS: '[DATABUS] GET Content Success',
    GET_CONTENT_ATTEMPT: '[DATABUS] GET Content Attempt',
    GET_CONTENT_FAILED: '[DATABUS] GET Content Failed',
    SET_IS_GET_CONTENT: '[DATABUS] SET IS GET Content',

    GET_FLIGHTS_SUCCESS: '[DATABUS] GET Flights Success',
    GET_FLIGHTS_ATTEMPT: '[DATABUS] GET Flights Attempt',
    GET_FLIGHTS_FAILED: '[DATABUS] GET Flights Failed',

    GET_WEATHER_SUCCESS: '[DATABUS] GET_WEATHER_SUCCESS',
    GET_WEATHER_FAILED: '[DATABUS] GET_WEATHER_FAILED',
    GET_WEATHER: '[DATABUS] GET_WEATHER',

    SET_POI_WALK_TIME: '[DATABUS] SET_POI_WALK_TIME',
    CHANGE_LANGUAGE: '[DATABUS] CHANGE_LANGUAGE',
    SET_IMAGES: '[DATABUS] SET IMAGES',

    SET_KIOSK_LOCATION: '[DATABUS] SET KIOSK Location',
    SET_PIN_COORDINATES: '[DATABUS] SET PIN Coordinates',
};
