import { IconModel } from './icon';

export class ListItemModel {
    id?: string;
    icon?: IconModel;
    title: string;
    description?: string;
    poiImage?: any;
    poiLargeImage?: any;
    moreInfo?: Array<any>;
    containerStyle?: any;
    locationDescription?: string;
    rating?: number;
    address?: string;
    location?: string;
    websiteURL?: string;
    phoneNumber?: string;

    constructor(props?: ListItemModel) {
        return {
            id: props?.id,
            icon: props?.icon,
            title: props?.title,
            description: props?.description,
            poiImage: props?.poiImage,
            poiLargeImage: props?.poiLargeImage,
            moreInfo: props?.moreInfo,
            containerStyle: props?.containerStyle,
            rating: props?.rating,
            address: props?.address,
            location: props?.location,
            websiteURL: props?.websiteURL,
            phoneNumber: props?.phoneNumber,
            locationDescription: props?.locationDescription,
        };
    }
}
