import { ButtonModel } from './button';

export class HeaderModel {
    logo: string;
    type?: 'expanded' | 'collapsed';
    actions?: Array<ButtonModel>;
    containerStyle?: any;
    showDateAndWeather?: boolean;
    showSubtitle?: boolean;

    constructor(props?: HeaderModel) {
        return {
            logo: props.logo,
            type: props.type || 'collapsed',
            actions: props.actions || [],
            showDateAndWeather: props.showDateAndWeather || false,
            containerStyle: props.containerStyle || {},
            showSubtitle: props.showSubtitle || false,
        };
    }
}
