import { ViewChild, Component, OnInit, OnChanges, Input } from '@angular/core';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ListItemModel } from '@app/models/list-item';
import _ from 'lodash';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnChanges {
    @ViewChild('sheet') sheet: BottomSheetComponent;

    @Input() items: Array<any> = [];
    @Input() isFlight: boolean;
    @Input() filterValue = '';
    @Input() param = '';

    poiListIncludesState = false;
    clickedItem: ListItemModel;
    itemProps: ListItemModel;
    hasIdentifier: boolean;
    itemsSortedArray = [];
    currentPage = '';

    constructor(private route: ActivatedRoute, private router: Router) {
        const path = router.routerState.snapshot.url.split('?')[0];
        const routes = path.split('/');
        this.currentPage = routes[routes.length - 1];
    }

    ngOnInit(): void {
        this.sortItemsByState();
    }

    ngOnChanges(changes: any): void {
        if (
            changes && changes?.items
                ? changes?.items.currentValue &&
                  !_.isEqual(
                      changes.items.currentValue,
                      changes.items.previousValue
                  )
                : false
        ) {
            this.items = changes.items.currentValue;

            this.sortItemsByState();
        }
    }

    sortItemsByState(): void {
        this.itemsSortedArray = this.items.slice();
        this.itemsSortedArray.sort((a) => {
            const poi = a?.moreInfo?.find((item) => item.id === 'STATE');

            if (poi) {
                this.poiListIncludesState = true;
                if (poi.text?.includes('OPEN')) {
                    return -1;
                } else {
                    return 1;
                }
            }
        });
    }

    onListItemClick(event: any): void {
        if (
            !this.sheet.modalTemplateRef ||
            this.sheet.modalTemplateRef?._state === 2
        ) {
            this.clickedItem = event;

            this.itemProps = {
                ...this.clickedItem,
                poiImage: undefined,
                poiLargeImage: undefined,
                description: '',
                containerStyle: {
                    padding: '0.5rem 0rem',
                },
            };
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    id: event.id,
                },
                queryParamsHandling: 'merge',
                // preserve the existing query params in the route
                skipLocationChange: false,
                // do not trigger navigation
            });

            this.sheet?.open();
        }
    }

    onCloseDetails(): void {
        this.clickedItem = null;
        history.back();
    }
}
