import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Components
import { KioskComponent } from './kiosk.component';

const routes: Routes = [
    {
        path: 'kiosk',
        component: KioskComponent,
    },
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class KioskRoutingModule {}
