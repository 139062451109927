export const colorSchema = [
    /*** BLACK ***/
    { name: '$black', value: '#000000' },
    { name: '$white', value: '#ffffff' },
    { name: '$primary-bg-color', value: '#492861' },
    { name: '$secondary-bg-color', value: '#653684' },

    { name: '$ivory', value: '#d6caca' },
    { name: '$green', value: '#009b77' },
    { name: '$red', value: '#a7002f' },
    { name: '$grey', value: '#6e6e6e' },
    { name: '$light-grey', value: '#efeff4' },
    { name: '$dark-blue', value: '#282959' },
    { name: 'fade-bg-color-version-modal', value: '#191919b3' },
    { name: 'bg-color-version-modal', value: '#ecf1f9' },
    { name: 'bg-map-section', value: '#d2d3d5' },
];
