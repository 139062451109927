import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';

@Component({
    selector: 'app-indicator',
    templateUrl: './indicator.component.html',
    styleUrls: ['./indicator.component.scss'],
})
export class IndicatorComponent implements OnInit {
    show: boolean;
    ngOnInit(): void {
        timer(200).subscribe(() => {
            this.show = true;
        });
    }
}
