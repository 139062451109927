import { EmergencyModel } from '@app/models/store/content/emergency';
import { selectEmergency } from '@app/store/selectors';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-kiosk',
    templateUrl: './kiosk.component.html',
    styleUrls: ['./kiosk.component.scss'],
})
export class KioskComponent implements OnDestroy {
    emergencyData: EmergencyModel = new EmergencyModel();
    emergencySubscription: Subscription;

    constructor(private store: Store) {
        const dataBusSelector = this.store.select(selectEmergency);

        this.emergencySubscription = dataBusSelector.subscribe((res) => {
            if (res && res.isGetContent) {
                this.emergencyData = res;
            }
        });
    }

    ngOnDestroy(): void {
        this.emergencySubscription?.unsubscribe();
    }
}
