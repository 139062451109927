import { TimeConversionService } from '@app/services/time-conversion/time-conversion.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-date-time',
    templateUrl: './date-time.component.html',
    styleUrls: ['./date-time.component.scss'],
})
export class DateTimeComponent implements OnInit, OnDestroy {
    dateTimeInterval: NodeJS.Timeout;
    time: string;

    constructor(private timeService: TimeConversionService) {
        this.dateTimeInterval = setInterval(() => {
            this.initializeDateTime();
        }, 1000);
    }

    ngOnInit(): void {
        this.initializeDateTime();
    }

    ngOnDestroy(): void {
        clearInterval(this.dateTimeInterval);
    }

    initializeDateTime(): void {
        this.time = this.timeService.formatDate('h:mm a', new Date(), true);
    }
}
