import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { MapWrapperService } from '@app/services/map-wrapper/map-wrapper.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { categoriesHeaderActions, headerActions } from '@app/props/header';
import { UtilityService } from '@app/services/utility/utility.service';
import { animationConfig } from '@app/animations/animation';
import { TranslateService } from '@ngx-translate/core';
import { changeLanguage } from '@app/store/actions';
import { Store } from '@ngrx/store';
import {
    ChildrenOutletContexts,
    ActivatedRoute,
    NavigationEnd,
    Router,
} from '@angular/router';

@Component({
    selector: 'app-microsite',
    templateUrl: './microsite.component.html',
    styleUrls: ['./microsite.component.scss'],
    animations: [animationConfig.fadOutRouteAnimation],
})
export class MicrositeComponent implements OnInit, AfterViewInit {
    @ViewChild('sheet') sheet: BottomSheetComponent;

    headerActions = headerActions;
    showDateAndWeather = false;
    subRoute = '';
    path = '';

    constructor(
        private mapWrapperService: MapWrapperService,
        private contexts: ChildrenOutletContexts,
        private utilityService: UtilityService,
        private translate: TranslateService,
        public route: ActivatedRoute,
        public router: Router,
        public store: Store
    ) {
        this.utilityService.setCategoryButtons();

        let url = this.router.url.split('/');
        this.path = url[url.length - 1];
        this.subRoute = url[url.length - 3];

        this.router.events.subscribe((routerEvent) => {
            if (routerEvent instanceof NavigationEnd) {
                url = this.router.url.split('/');
                this.showDateAndWeather = url[url.length - 1] === 'categories';
                this.path = url[url.length - 1];
                this.subRoute = url[url.length - 2];

                if (this.router.url.includes('home')) {
                    this.headerActions = headerActions;
                } else {
                    this.headerActions = categoriesHeaderActions;
                }
            }
        });
    }

    ngOnInit(): void {
        const splitURL = this.router.url.split('?')[0].split('/');
        const isRedirect = splitURL[splitURL.length - 1] === 'home';

        const idQueryParam =
            this.route.snapshot.queryParams['id'] && isRedirect;
        if (!idQueryParam && !this.utilityService.isMicrositeInitialized) {
            this.router.navigate(['/microsite/home']);
        }
        this.utilityService.isMicrositeInitialized = true;
    }

    ngAfterViewInit(): void {
        if (this.router.url.includes('home')) {
            this.sheet?.open();
        } else {
            this.sheet?.close();
        }
    }

    headerActionsClicked(event: {
        toggle: boolean;
        id: string;
        text?: string;
    }): void {
        switch (event.id) {
            case 'SEARCH':
                this.router.navigate(['microsite/search']);
                break;
            case 'CATEGORY':
                this.router.navigate(['microsite/home']);

                this.utilityService.triggerFloorChange.emit(
                    this.utilityService.kioskFloor
                );
                setTimeout(() => {
                    this.mapWrapperService.setMapPosition(true);
                    this.mapWrapperService.resize();
                }, 100);

                break;
            case 'LANGUAGE':
                this.translate.setDefaultLang(event.text || 'en');
                this.translate.use(event.text || 'en');
                this.store.dispatch(changeLanguage({ payload: event.text }));
                break;
            case 'LOGO':
                this.router.navigate(['microsite/home']);
                break;
        }
    }

    getRouteAnimationData(): string {
        return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
            'animation'
        ];
    }
}
