import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { YouTubePlayerModule } from '@angular/youtube-player';
import { KioskRoutingModule } from './kiosk-routing.module';
import { SharedModule } from '../shared/shared.module';

// Directive
import { DynamicDetailsDirective } from '@app/shared/directives/dynamic-details.directive';

// Components
import { NotificationComponent } from './components/notification/notification.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { HomeComponent } from './sections/home/home.component';
import { KioskComponent } from './kiosk.component';
import { AdvertisementComponent } from './components/advertisement/advertisement.component';
import { EmergencyComponent } from './components/emergency/emergency.component';
import { ContentComponent } from './components/content/content.component';
import { WebviewDirective } from '@app/shared/directives';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        VideoPlayerComponent,
        KioskComponent,
        HomeComponent,
        NotificationComponent,
        AdvertisementComponent,
        EmergencyComponent,
        ContentComponent,
        WebviewDirective,
        // Directives
        DynamicDetailsDirective,
    ],
    imports: [
        CommonModule,
        KioskRoutingModule,
        YouTubePlayerModule,
        SharedModule,
        TranslateModule,
    ],
})
export class KioskModule {}
