import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TextInputModel } from '@app/models/text-input';

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent {
    @Input() props: TextInputModel;
    @Output() textChange = new EventEmitter();
    @Output() textCleared = new EventEmitter();
    @Output() onFocus = new EventEmitter();

    OnTextChange(event: string): void {
        this.textChange.emit(event);
    }
}
