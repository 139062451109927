import { NgModule } from '@angular/core';

//Routing Modules
import { MainRoutingModule } from '@app/main/main-routing.module';
import { RouterModule } from '@angular/router';

import { MapWrapperService } from '@app/services/map-wrapper/map-wrapper.service';
import { MicrositeModule } from '@app/microsite/microsite.module';
import { KioskModule } from '@app/kiosk/kiosk.module';

declare const vg;

@NgModule({
    declarations: [],
    imports: [MainRoutingModule, RouterModule, MicrositeModule, KioskModule],
    providers: [
        {
            provide: 'mapFactory',
            useFactory: () => new vg.mapviewer.Mapviewer(),
        },
        MapWrapperService,
    ],
})
export class MainModule {}
