export class TextInputModel {
    icon?: string;
    text?: string;
    placeholder?: string;
    containerStyle?: any;
    constructor(props?: TextInputModel) {
        return {
            icon: props.icon,
            text: props.text,
            placeholder: props.placeholder,
            containerStyle: props.containerStyle,
        };
    }
}
