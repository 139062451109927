import { AttractionModel } from './content/attraction';
import { EmergencyModel } from './content/emergency';
import { CategoryModel } from './content/category';
import { VideoModel } from './content/video';
import { KioskModel } from './content/kiosk';
import { PageModel } from './content/page';
import { PoiModel } from './content/poi';

export interface LanguagesString {
    [key: string]: string;
}

export class ContentModel {
    attractions?: AttractionModel[];
    categories?: CategoryModel[];
    emergency?: EmergencyModel;
    hotels?: AttractionModel[];
    kioskNames?: KioskModel[];
    videos?: VideoModel[];
    lastUpdated?: string;
    pages?: PageModel[];
    pois?: PoiModel[];
    isGetContent?: boolean;
    mapElementIDs?: any[];

    constructor(props: ContentModel) {
        return {
            attractions: props?.attractions || [],
            categories: props?.categories || [],
            emergency: props?.emergency || {},
            hotels: props?.hotels || [],
            kioskNames: props?.kioskNames || [],
            lastUpdated: props?.lastUpdated || '',
            pages: props?.pages || [],
            pois: props?.pois || [],
            videos: props?.videos || [],
            isGetContent: props?.isGetContent || false,
            mapElementIDs: props?.mapElementIDs || [],
        };
    }
}
