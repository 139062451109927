import { LanguagesString } from './store/content';

export class ContentDataModel {
    type: string;
    props: ContentModel;

    constructor(props?: ContentDataModel) {
        return {
            type: props?.type,
            props: props?.props
                ? new ContentModel(props.props)
                : new ContentModel(),
        };
    }
}

export class ContentModel {
    key?: string;
    title?: LanguagesString;
    description?: LanguagesString;
    category?: string;
    customClasses?: any;

    constructor(props?: ContentModel) {
        return {
            key: props?.key,
            title: props?.title
                ? {
                      en: props.title?.en || '',
                      es: props.title?.es || '',
                  }
                : { en: '', es: '' },
            description: props?.description
                ? {
                      en: props.description?.en || '',
                      es: props.description?.es || '',
                  }
                : { en: '', es: '' },
            category: props?.category || '',
            customClasses: props?.customClasses || {},
        };
    }
}

export class DynamicDataModel {
    component?: any;
    props: ContentModel;
    type: string;

    constructor(props?: DynamicDataModel) {
        return {
            component: props?.component || null,
            props: new ContentModel(props?.props),
            type: props?.type || '',
        };
    }
}
