/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { OnChanges, Component, DoCheck, Input } from '@angular/core';
import { FlightModel } from '@app/models/flight';

@Component({
    selector: 'app-flight',
    templateUrl: './flight.component.html',
    styleUrls: ['./flight.component.scss'],
})
export class FlightComponent implements DoCheck, OnChanges {
    @Input() props: FlightModel = new FlightModel();

    status = ['Delayed', 'Cancelled'];
    isScrollable: boolean;

    ngDoCheck(): void {
        const el = document.getElementById(this.props.id);
        const partnerSection = document.getElementById('partner-section');

        this.isScrollable = el?.scrollWidth > partnerSection?.clientWidth;
    }

    ngOnChanges(changes: any): void {
        if (changes && changes.props && changes.props.currentValue) {
            this.props = new FlightModel(changes.props.currentValue);
        }
    }
}
