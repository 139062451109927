export class IconModel {
    icon?: string;
    tooltip?: string;
    imageSrc?: any;
    containerStyle?: any;
    containerIconStyle?: any;
    customClasses?: { [key: string]: boolean };

    constructor(props?: IconModel) {
        return {
            icon: props.icon,
            tooltip: props?.tooltip,
            imageSrc: props?.imageSrc,
            containerStyle: props.containerStyle,
            containerIconStyle: props.containerIconStyle,
            customClasses: props.customClasses,
        };
    }
}
