import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    APP_INITIALIZER,
    InjectionToken,
    Injector,
    NgModule,
} from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

// NG Translate
import {
    TranslateModule,
    TranslateLoader,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppConfig } from '../environments/environment';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { LOCATION_INITIALIZED } from '@angular/common';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { combinedReducers } from './store/reducers';
import { effects } from './store/effects';

// Modules
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { MainComponent } from './main/main.component';
import { MainModule } from './main/main.module';

import { PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';

import {
    ScreenTrackingService,
    UserTrackingService,
    provideAnalytics,
    getAnalytics,
} from '@angular/fire/analytics';

import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

// AoT requires an exported function for factories
export const httpLoaderFactory = (http: HttpClient): TranslateHttpLoader => {
    return new TranslateHttpLoader(
        http,
        './assets/i18n/',
        `.json?cb=${new Date().getTime()}`
    );
};

export const ReducerToken = new InjectionToken('Registered Reducer');
export function getReducers(): typeof combinedReducers {
    return combinedReducers;
}

export function localStorageSyncReducer(
    reducer: ActionReducer<any>
): ActionReducer<any> {
    return localStorageSync({
        keys: ['dataBus'],
        rehydrate: true, //must be true in order for local storage to persist
    })(reducer);
}

export function appInitializerFactory(
    translate: TranslateService,
    injector: Injector
) {
    return (): Promise<any> =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(
                LOCATION_INITIALIZED,
                Promise.resolve(null)
            );
            locationInitialized.then(() => {
                const langToSet = 'en';
                translate.setDefaultLang('en');
                translate.use(langToSet).subscribe(
                    () => {
                        // eslint-disable-next-line
                        console.info(
                            `Successfully initialized '${langToSet}' language.'`
                        );
                    },
                    () => {
                        // eslint-disable-next-line
                        console.error(
                            `Problem with '${langToSet}' language initialization.'`
                        );
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
    declarations: [AppComponent, MainComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        SharedModule,
        AppRoutingModule,
        MainModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        StoreModule.forRoot(ReducerToken, {
            metaReducers,
        }),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: AppConfig.production,
        }),
        provideFirebaseApp(() => initializeApp(AppConfig.firebase)),
        provideAuth(() => getAuth()),
        providePerformance(() => getPerformance()),
        provideStorage(() => getStorage()),
        provideAnalytics(() => getAnalytics()),
    ],
    providers: [
        { provide: FIREBASE_OPTIONS, useValue: AppConfig.firebase },
        PerformanceMonitoringService,
        ScreenTrackingService,
        UserTrackingService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        [{ provide: ReducerToken, useFactory: getReducers }],
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
