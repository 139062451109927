import { OperatingHoursModel } from '@app/models/store/content/poi';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'environments/environment';
import * as timezone from 'dayjs/plugin/timezone';
import { Injectable } from '@angular/core';
import * as utc from 'dayjs/plugin/utc';
import * as dayjs from 'dayjs';

dayjs.extend(timezone);
dayjs.extend(utc);

type dateType = string | number | dayjs.Dayjs | Date | null | undefined;

@Injectable({
    providedIn: 'root',
})
export class TimeConversionService {
    constructor(private translate: TranslateService) {}

    formatDate(format: string, date?: dateType, timeZone?: boolean): string {
        date = date || new Date();
        if (timeZone) {
            return dayjs(date).tz(AppConfig.airportZone, false).format(format);
        }
        return dayjs(date).format(format);
    }

    isTimeBetween(operatingHours: Array<OperatingHoursModel>): boolean {
        const currentDay = new Date().getDay().toString();
        const currentTime = this.formatDate('HH:mm', undefined, true);

        let isBetween = false;
        operatingHours.forEach((element) => {
            if (element.day.includes(currentDay)) {
                if (
                    element.openingTime <= currentTime &&
                    element.closingTime >= currentTime
                ) {
                    isBetween = true;
                }
            }
        });

        return isBetween;
    }
}
