/* eslint-disable @ngrx/no-store-subscription */
import { selectContent, selectDataBus } from '@app/store/selectors';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DataBusStateModel } from '@app/models/store/data-bus';
import { KioskModel } from '@app/models/store/content/kiosk';
import { ContentModel } from '@app/models/store/content';
import { PoiModel } from '@app/models/store/content/poi';
import { EventEmitter, Injectable } from '@angular/core';
import { containerIconStyle } from '@app/props/header';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'environments/environment';
import { Title } from '@angular/platform-browser';
import { FlightModel } from '@app/models/flight';
import { ButtonModel } from '@app/models/button';
import { setImages } from '@app/store/actions';
import { Store } from '@ngrx/store';
const convert = require('xml-js');
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    public triggerFloorChange = new EventEmitter();

    fireStoreIcons: {
        categories?: { [key: string]: string };
        hotels?: {
            smallSize: { [key: string]: string };
            largeSize: { [key: string]: string };
        };
        attractions?: {
            smallSize: { [key: string]: string };
            largeSize: { [key: string]: string };
        };
        pois?: {
            smallSize: { [key: string]: string };
            largeSize: { [key: string]: string };
        };
        pages?: { [key: string]: string };
        airlinesCodes?: { [key: string]: string };
    } = {
        attractions: { smallSize: {}, largeSize: {} },
        hotels: { smallSize: {}, largeSize: {} },
        categories: {},
        pois: { smallSize: {}, largeSize: {} },
        pages: {},
        airlinesCodes: {},
    };

    collapsedCategoryButtons: Array<ButtonModel> = [];
    categoryButtons: Array<ButtonModel> = [];
    isMicrositeInitialized = false;
    kioskNames: KioskModel[];
    selectedLanguage = 'en';
    mapElementIDs: string[];
    flights: FlightModel[];
    kioskLocation: string;
    content: ContentModel;
    pinCoordinates: any;
    mapCategories: any;
    kioskFloor: string;
    pois: PoiModel[];

    constructor(
        public fireStorage: AngularFireStorage,
        private translate: TranslateService,
        public titleService: Title,
        private store: Store
    ) {
        translate.onLangChange.subscribe(() => {
            this.setSiteName();
        });

        const dataBusSelector = this.store.select(selectDataBus);
        dataBusSelector.subscribe((res) => {
            this.content = new ContentModel(res.content);
            this.mapCategories = res.mapCategories;
            this.pois = res.content?.pois;
            this.mapElementIDs = res.content?.mapElementIDs;
            this.flights = res?.flights;
            this.kioskNames = res.content?.kioskNames;

            this.selectedLanguage = res.language;
            this.translate.setDefaultLang(res.language ? res.language : 'en');
            this.translate.use(res.language);

            this.kioskLocation =
                res.kioskLocation || AppConfig.defaultKioskLocation;
            this.pinCoordinates = res.pinCoordinates;
            this.kioskFloor = res.kioskFloor || 'B1-UL00';
        });
    }

    xmlToJson(cacheFlights: any): any {
        return JSON.parse(
            convert.xml2json(cacheFlights, {
                compact: true,
                spaces: 0,
            })
        );
    }

    hasTranslationKey(key: string): boolean {
        const translation = key ? this.translate.instant(key) : key;

        return translation !== key && translation !== '';
    }

    setSiteName(): void {
        const siteName = `MAIN.${AppConfig.environment}`;
        //condition to find non existing path => returns the key instead of a translation
        if (!this.hasTranslationKey(siteName)) {
            this.titleService.setTitle(
                this.translate.instant(`MAIN.SITE_NAME`)
            );
        } else {
            this.titleService.setTitle(this.translate.instant(siteName));
        }
    }

    setImageDownloadURL(
        image: string,
        source: string,
        item: any,
        isLargeImageSize = false
    ): void {
        const ref = this.fireStorage.ref(image);
        ref.getDownloadURL().subscribe((res) => {
            if (
                (source === 'pois' ||
                    source === 'hotels' ||
                    source === 'attractions') &&
                AppConfig.appType === 'microsite'
            ) {
                if (isLargeImageSize) {
                    this.fireStoreIcons[source].largeSize[item.key || item.id] =
                        res;
                } else {
                    this.fireStoreIcons[source].smallSize[item.key || item.id] =
                        res;
                }
            } else {
                this.fireStoreIcons[source][item.key || item.id] = res;
            }

            this.fireStoreIcons.categories = this.toLowerKeys(
                this.fireStoreIcons.categories
            );

            const d = _.cloneDeep(this.fireStoreIcons);
            this.store.dispatch(setImages({ payload: d }));
        });
    }

    getKeyFromRoute(url: string): string {
        const routerSplit = url.split('/');
        return routerSplit[routerSplit.length - 1];
    }

    restructureFlights(flights: FlightModel[]): {
        flights: FlightModel[];
        airlinesCodes: Array<{ key: string }>;
    } {
        const restructuredFlights: {
            flights: FlightModel[];
            airlinesCodes: Array<{ key: string }>;
        } = { flights: [], airlinesCodes: [] };

        let flightIDs = [];

        flights?.forEach((item) => {
            const partners = [];
            item?.partners?.forEach((partner) => {
                flightIDs.push(partner.linecode.toUpperCase());
                partners.push({
                    text: partner.linecode.toUpperCase() + ' ' + partner.number,
                    linecode: partner.linecode.toUpperCase(),
                });
            });
            flightIDs.push(item.linecode.toUpperCase());

            restructuredFlights.flights.push(
                new FlightModel({
                    ...item,
                    departureTime: item?.actual || item?.schedule,
                    partners,
                })
            );
        });

        flightIDs = [...new Set(flightIDs)];

        restructuredFlights.airlinesCodes = flightIDs.map((item) => {
            return { key: item };
        });

        return restructuredFlights;
    }

    preload(content: DataBusStateModel): void {
        let sources = {};
        const listImages: {
            key: string;
            extension: string;
            source: string;
        }[] = [];
        if (AppConfig.appType === 'microsite') {
            sources = {
                categories: 'poiCategoryIcons',
                hotels: 'hotelImages',
                attractions: 'attractionImages',
                pois: 'poiIcons',
                airlinesCodes: 'airlineLogos',
            };

            let count = Object.keys(sources).length;

            Object.keys(sources).forEach((source) => {
                this.fireStorage.storage
                    ?.ref()
                    .child(sources[source])
                    .listAll()
                    .then((item) => {
                        count--;

                        item.items.forEach((imageInfo) => {
                            const image = imageInfo.name.split('.');

                            listImages.push({
                                key: image[0],
                                extension: image[1],
                                source: sources[source],
                            });
                        });

                        if (count === 0) {
                            this.getImagesURL(content, sources, listImages);
                        }
                    });
            });
        } else if (
            AppConfig.appType === 'web' ||
            AppConfig.appType === 'electron'
        ) {
            sources = {
                pages: 'pageImages',
            };

            this.fireStorage.storage
                .ref()
                .child('pageImages')
                .listAll()
                .then((item) => {
                    item.items.forEach((imageInfo) => {
                        const image = imageInfo.name.split('.');

                        listImages.push({
                            key: image[0],
                            extension: image[1],
                            source: 'pageImages',
                        });
                    });

                    this.getImagesURL(content, sources, listImages);
                });
        }
    }

    getImagesURL(
        dataBus: DataBusStateModel,
        sources: any,
        imagesList = []
    ): void {
        const imagesArray: Array<{
            imageURL: string;
            source: string;
            item: any;
            isLargeImageSize?: boolean;
        }> = [];

        Object.keys(sources).forEach((source) => {
            if (source === 'airlinesCodes') {
                dataBus[source].forEach((item) => {
                    const imageName = imagesList?.find(
                        (image) =>
                            image.key === item.key &&
                            image.source === sources[source]
                    );

                    if (imageName) {
                        imagesArray.push({
                            imageURL: `/${imageName.source}/${
                                imageName.key +
                                this.is200(source, imageName.extension)
                            }`,
                            source,
                            item,
                        });
                    }
                });
            } else {
                dataBus.content[source].forEach((item) => {
                    const imageName = imagesList?.find(
                        (image) =>
                            image.key === (item.key || '' + item.id) &&
                            image.source === sources[source]
                    );

                    if (imageName) {
                        if (
                            (source === 'pois' ||
                                source === 'hotels' ||
                                source === 'attractions') &&
                            AppConfig.appType === 'microsite'
                        ) {
                            imagesArray.push({
                                imageURL: `/${imageName.source}/${
                                    imageName.key + '_1242x863.webp'
                                }`,
                                source,
                                item,
                                isLargeImageSize: true,
                            });
                        }

                        if (
                            source === 'pages' &&
                            AppConfig.appType !== 'microsite'
                        ) {
                            imagesArray.push({
                                imageURL: `/${imageName.source}/${
                                    imageName.key + '_1242x863.webp'
                                }`,
                                source,
                                item,
                            });
                        } else {
                            imagesArray.push({
                                imageURL: `/${imageName.source}/${
                                    imageName.key +
                                    this.is200(source, imageName.extension)
                                }`,
                                source,
                                item,
                            });
                        }
                    }
                });
            }
        });

        imagesArray.forEach((element) => {
            this.setImageDownloadURL(
                element.imageURL,
                element.source,
                element.item,
                element.isLargeImageSize || false
            );
        });
    }

    toLowerKeys(obj: any): any {
        return Object.keys(obj).reduce((accumulator, key) => {
            accumulator[key.toLowerCase()] = obj[key];
            return accumulator;
        }, {});
    }

    getState(flag: boolean): string {
        return `MICROSITE.POIS.${flag ? 'OPEN' : 'CLOSE'}`;
    }

    is200(src: string, extension: string): string {
        if (src === 'pages' || src === 'airlinesCodes') {
            return '.' + extension;
        } else {
            return '_200x200.webp';
        }
    }

    backButtonClicked(): void {
        history.back();
    }

    setCategoryButtons(): void {
        this.store.select(selectContent).subscribe((res) => {
            const collapsedCategories = [];
            const tempCategories = [];
            res?.categories?.forEach((item) => {
                const condition =
                    item.key.toLowerCase() === 'hotel'
                        ? res?.hotels?.length > 0
                        : item.key.toLowerCase() === 'attraction'
                        ? res?.attractions?.length > 0
                        : res?.pois.findIndex(
                              (poi) =>
                                  poi.category.toLowerCase() ===
                                  item.key.toLowerCase()
                          ) !== -1;

                if (condition || item.key.toLowerCase() === 'flight') {
                    const props = {
                        ...item,
                        id: item.key,
                        icon: {
                            imageSrc:
                                this.fireStoreIcons.categories[
                                    item.key.toLowerCase()
                                ] ||
                                `assets/cms-alternative/${item.key.toLowerCase()}.png`,
                            containerStyle: {
                                width: '40%',
                                height: '50%',
                                borderRadius: '0px',
                                filter: 'brightness(0) invert(1)',
                            },
                            containerIconStyle,
                        },
                    };

                    tempCategories.push({
                        ...props,
                        text: { en: item?.name?.en, es: item?.name?.es },
                        containerStyle: {
                            backgroundColor: 'var(--secondary-bg-color)',
                            padding: '12px',
                            width: '100%',
                            height: 'calc((100vw / 3) - 2rem)',
                        },
                    });

                    collapsedCategories.push({
                        ...props,
                        icon: {
                            ...props.icon,
                            customClasses: 'collapsed-icon',
                        },
                        customContainerClasses: {
                            'btn-collapsed-view': true,
                        },
                        containerStyle: {
                            backgroundColor: 'var(--secondary-bg-color)',
                            padding: '12px',
                            width: '100%',
                        },
                    });
                }
            });

            if (!_.isEqual(tempCategories, this.categoryButtons)) {
                this.categoryButtons = tempCategories;
                this.collapsedCategoryButtons = collapsedCategories;
            }
        });
    }
}
