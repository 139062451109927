import { UtilityService } from '@app/services/utility/utility.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-collapsed-categories',
    templateUrl: './collapsed-categories.component.html',
    styleUrls: ['./collapsed-categories.component.scss'],
})
export class CollapsedCategoriesComponent {
    constructor(public utility: UtilityService, public router: Router) {}

    categoryButtonClicked(event: string): void {
        this.router.navigate(['microsite/categories/', event.toLowerCase()]);
    }
}
