import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    showMap = false;
    type: string;

    constructor(
        private translate: TranslateService,
        public cdr: ChangeDetectorRef,
        public router: Router
    ) {
        this.translate.addLangs(['en', 'es']);
        this.translate.setDefaultLang('en');
        this.translate.use('en');

        this.type = AppConfig.appType;
    }

    ngOnInit(): void {
        if (this.type === 'microsite') {
            this.router.events.subscribe((routerEvent) => {
                if (routerEvent instanceof NavigationEnd) {
                    this.showMap =
                        routerEvent.urlAfterRedirects.includes('home');

                    document.body.style.overflow = this.showMap
                        ? 'hidden'
                        : 'unset';
                    document.body.style.width = this.showMap ? '' : '100%';
                    document.body.style.position = this.showMap ? '' : 'fixed';

                    this.cdr.detectChanges();
                }
            });
        }
    }
}
