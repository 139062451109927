import { MainRoutingModule } from './main/main-routing.module';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('@app/main/main.module').then((m) => m.MainModule),
    },
    {
        path: '**',
        loadChildren: () =>
            import('@app/main/main.module').then((m) => m.MainModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), MainRoutingModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
