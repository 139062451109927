import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { MicrositeRoutingModule } from './microsite-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

// Components
import { CopyrightComponent } from './components/copyright/copyright.component';
import { MicrositeComponent } from './microsite.component';

// HammerJS
import * as Hammer from 'hammerjs';
import {
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig,
    HammerModule,
} from '@angular/platform-browser';
import { CoreModule } from '@app/core/core.module';

@Injectable({
    providedIn: 'root',
})
export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: { enable: true, direction: Hammer.DIRECTION_ALL },
    };
}

@NgModule({
    declarations: [MicrositeComponent, CopyrightComponent],
    imports: [
        CommonModule,
        MicrositeRoutingModule,
        TranslateModule,
        SharedModule,
        HammerModule,
        CoreModule,
    ],
    providers: [
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
    ],
})
export class MicrositeModule {}
