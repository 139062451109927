import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { QRCodeModule } from 'angularx-qrcode';

// Components
import { CollapsedCategoriesComponent } from '@app/microsite/components/collapsed-categories/collapsed-categories.component';
import { FlightIconComponent } from '@app/microsite/components/flight/flight-icon/flight-icon.component';
import { BottomSheetComponent } from '@app/microsite/components/bottom-sheet/bottom-sheet.component';
import { PoiListComponent } from '@app/kiosk/components/poi-list/poi-list.component';
import { ButtonComponent } from '@app/microsite/components/button/button.component';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { DateTimeComponent } from './components/date-time/date-time.component';
import { WeatherComponent } from './components/weather/weather.component';
import { HeaderComponent } from './components/header/header.component';
import { NoSanitizePipe } from './pipes/no-sanitize/no-sanitize.pipe';
import { QrComponent } from '@app/kiosk/components/qr/qr.component';
import { IconComponent } from './components/icon/icon.component';
import { MapComponent } from './components/map/map.component';

@NgModule({
    declarations: [
        CollapsedCategoriesComponent,
        BottomSheetComponent,
        DateTimeComponent,
        IconComponent,
        ButtonComponent,
        HeaderComponent,
        WeatherComponent,
        MapComponent,
        IndicatorComponent,
        QrComponent,
        PoiListComponent,
        FlightIconComponent,
        NoSanitizePipe,
    ],
    imports: [CommonModule, TranslateModule, MaterialModule, QRCodeModule],
    exports: [
        MaterialModule,
        CollapsedCategoriesComponent,
        BottomSheetComponent,
        DateTimeComponent,
        ButtonComponent,
        IconComponent,
        WeatherComponent,
        HeaderComponent,
        MapComponent,
        QrComponent,
        PoiListComponent,
        FlightIconComponent,
        NoSanitizePipe,
        TranslateModule,
    ],
})
export class SharedModule {}
