import { EmptyStateModel } from '@app/models/empty-state';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnChanges {
    @Input() props: EmptyStateModel;

    ngOnChanges(): void {
        this.props = new EmptyStateModel(this.props);
    }
}
