import { AppConfig } from 'environments/environment';
import { ContentModel } from './content';
import { FlightModel } from '../flight';

export class DataBusStateModel {
    weather?: {
        temperature?: number;
        weatherImage?: {
            mode?: string;
            key?: string;
        };
    };
    content?: ContentModel;
    kioskLocation?: string;
    pinCoordinates?: any;
    kioskFloor?: string;
    mapCategories?: any;
    cfVersion?: string;
    language?: string;
    images?: any;
    flights?: FlightModel[];
    airlinesCodes?: Array<{ key?: string }>;

    constructor(props: DataBusStateModel) {
        return {
            weather: props.weather || {
                temperature: null,
                weatherImage: { mode: '', key: '' },
            },
            content: props.content,
            cfVersion: props.cfVersion || '',
            mapCategories: props.mapCategories,
            language: props.language || 'en',
            images: props.images,
            kioskLocation:
                props.kioskLocation || AppConfig.defaultKioskLocation,
            pinCoordinates: props.pinCoordinates || null,
            kioskFloor: props.kioskFloor || 'B1-UL00',
            flights: props?.flights || [],
            airlinesCodes: props?.airlinesCodes || [],
        };
    }
}
