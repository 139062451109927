import {
    transition,
    trigger,
    animate,
    state,
    style,
} from '@angular/animations';

export const animationConfig = {
    shimmerAnimation: trigger('shimmerAnimation', [
        state('true', style({ 'background-position': '-2rem top' })),
        state('false', style({ 'background-position': '110% top' })),
        transition('true => false', animate(1000)),
    ]),
    fadOutRouteAnimation: trigger('routeAnimations', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('800ms ease-in-out', style({ opacity: 1 })),
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('800ms ease-in-out', style({ opacity: 0 })),
        ]),
    ]),
};
