import { EmergencyModel } from '@app/models/store/content/emergency';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-emergency',
    templateUrl: './emergency.component.html',
    styleUrls: ['./emergency.component.scss'],
})
export class EmergencyComponent {
    @Input() props: EmergencyModel = new EmergencyModel();
}
