import { colorSchema } from 'assets/colors/color-schema';
import { Component } from '@angular/core';

@Component({
    selector: 'app-colors',
    templateUrl: './colors.component.html',
    styleUrls: ['./colors.component.scss'],
})
export class ColorsComponent {
    tableData = colorSchema;
}
