import { MicrositeComponent } from './microsite.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'microsite/home',
        pathMatch: 'full',
    },
    {
        path: '',
        component: MicrositeComponent,
        pathMatch: 'prefix',
        children: [
            {
                path: 'search',
                loadChildren: () =>
                    import('@app/microsite/pages/search/search.module').then(
                        (m) => m.SearchModule
                    ),
            },
            {
                path: 'categories/:id',
                loadChildren: () =>
                    import('@app/microsite/pages/listing/listing.module').then(
                        (m) => m.ListingModule
                    ),
            },
            {
                path: 'categories',
                data: { animation: 'fadOutRouteAnimation' },
                loadChildren: () =>
                    import(
                        '@app/microsite/pages/categories/categories.module'
                    ).then((m) => m.CategoriesModule),
            },
            {
                path: '**',
                redirectTo: 'home',
            },
        ],
    },
    {
        path: 'home',
        data: { animation: 'fadOutRouteAnimation' },
        component: MicrositeComponent,
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MicrositeRoutingModule {}
