import { AdvertisementComponent } from '@app/kiosk/components/advertisement/advertisement.component';
import { NotificationComponent } from '@app/kiosk/components/notification/notification.component';
import { VideoPlayerComponent } from '@app/kiosk/components/video-player/video-player.component';
import { ContentComponent } from '@app/kiosk/components/content/content.component';
import { DynamicDataModel } from '@app/models/content-data';
import { selectContent } from '@app/store/selectors';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
    contentTiming = {
        notification: 30000,
        information: 120000,
        advertisement: 30000,
        youtube: 300000,
    };

    contentData: DynamicDataModel;
    contents: DynamicDataModel[];
    emergency: boolean;
    index = -1;
    contentInProgress: boolean;
    currentlyRotating: boolean;

    constructor(private store: Store) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(tag);

        const dataBusSelector = this.store.select(selectContent);

        dataBusSelector.subscribe((contentItems) => {
            if (contentItems.isGetContent) {
                this.emergency = contentItems?.emergency?.enabled || false;
            }

            const videos: DynamicDataModel[] = [];
            contentItems.videos?.forEach((video) => {
                if (
                    video.type.toLowerCase() === 'youtube' &&
                    !video.isDisabled
                ) {
                    videos.push(
                        new DynamicDataModel({
                            type: 'youtube',
                            component: VideoPlayerComponent,
                            props: {
                                key: video.videoId,
                                category: 'youtube',
                            },
                        })
                    );
                }
            });

            const pages: DynamicDataModel[] = [];
            contentItems.pages?.forEach((page) => {
                let component = null;
                if (page.category === 'information') {
                    component = ContentComponent;
                } else if (page.category === 'advertisement') {
                    component = AdvertisementComponent;
                } else if (page.category === 'notification') {
                    component = NotificationComponent;
                }
                pages.push(
                    new DynamicDataModel({
                        type: page.category,
                        component,
                        props: { ...page },
                    })
                );
            });

            this.contents = videos.concat(pages);
            this.displayContent();
        });
    }

    displayContent($event?: string): Promise<any> {
        if (this.contents.length === 0 || this.emergency) {
            this.contentInProgress = false;
            this.currentlyRotating = false;
            this.contentData = null;
            this.index = -1;
            return;
        }

        if (!this.contentInProgress) {
            this.rotateContent();
            this.contentInProgress = true;
        }
    }

    async rotateContent(): Promise<void> {
        this.index = (this.index + 1) % this.contents.length;
        if (this.index < this.contents.length) {
            this.contentData = this.contents[this.index];
        } else {
            this.index = 0;
            this.contentData =
                this.contents.length > 0 ? this.contents[0] : null;
        }
        const contentTime = this.contentTiming[this.contentData.props.category];

        if (this.contentData.props.category === 'youtube') {
            this.currentlyRotating = false;
        } else {
            await this.delay(contentTime - 100);
            this.currentlyRotating = false;
            return this.rotateContent();
        }
    }

    rotateVideo($event) {
        if ($event.data === 0 && !this.currentlyRotating) {
            this.rotateContent();
            this.currentlyRotating = true;
        }
    }

    async delay(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}
