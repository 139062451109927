import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
    @Input() rating = 0;

    ratingStars: Array<number>;

    ngOnInit(): void {
        if (this.rating !== 0) {
            this.ratingStars = new Array(5);
        }
    }

    evaluateStar(position: number): string {
        if (this.rating >= position) {
            return 'star-filled';
        } else if (
            this.rating < position &&
            Math.ceil(this.rating) === position
        ) {
            return 'star-half';
        } else {
            return 'star';
        }
    }
}
