export class PoiListModel {
    title: string;
    list?: Array<{
        title: string;
        items: Array<{
            floorNb: string;
            name: string;
        }>;
    }>;
    color?: string;
    constructor(props?: PoiListModel) {
        return {
            title: props.title,
            list: props.list || [],
            color: props.color || 'var(--primary-bg-color)',
        };
    }
}
