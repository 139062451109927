export class FlightModel {
    id?: string;
    logo?: string;
    city?: string;
    gate?: string;
    line?: string;
    linecode?: string;
    cityCode?: string;
    /* eslint-disable  id-blacklist */
    number?: string;
    status?: string;
    walkTime?: string;
    departureTime?: string;
    adi?: string;
    partners?: Array<FlightPartnerModel>;
    containerStyle?: any;
    actual?: string;
    schedule?: string;

    constructor(props?: FlightModel) {
        return {
            id: props?.id,
            logo: props?.logo,
            city: props?.city,
            gate: props?.gate,
            line: props?.line,
            linecode: props?.linecode,
            cityCode: props?.cityCode,
            /* eslint-disable  id-blacklist */
            number: props?.number,
            status: props?.status,
            walkTime: props?.walkTime,
            departureTime: props?.departureTime,
            adi: props?.adi,
            partners: props?.partners || [],
            containerStyle: props?.containerStyle,
        };
    }
}

export class FlightPartnerModel {
    linecode?: string;
    number?: string;
    logo?: string;
    text?: string;

    constructor(props?: FlightPartnerModel) {
        return {
            linecode: props?.linecode || '',
            logo: props?.logo || null,
            text: props?.text || '',
            number: props?.number,
        };
    }
}
