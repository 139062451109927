import { Pipe, PipeTransform } from '@angular/core';
import { FlightModel } from '@app/models/flight';
import { EventHubService } from '@app/services/eventHub.service';

@Pipe({
    name: 'filterFlights',
})
export class FilterFlightsPipe implements PipeTransform {
    constructor(private eventHub: EventHubService) {}

    transform(
        array: FlightModel[],
        filterString: string,
        isFlight = false
    ): any[] {
        if (!isFlight) {
            return array;
        }

        const filteredFlights = array.filter((item) =>
            this.iterateAttributes(item, filterString)
        );

        this.eventHub.triggerFilterFlightsUpdate.emit(filteredFlights);

        return filteredFlights;
    }

    iterateAttributes(flight: any, event: string): boolean {
        const attr = ['city', 'line', 'linecode', 'gate', 'number', 'cityCode'];
        let flag: boolean;
        attr.forEach((atr) => {
            flag =
                flag ||
                this.format(flight[atr])?.includes(this.format(event)) ||
                this.format(
                    flight['linecode']?.concat(flight['number'])
                )?.startsWith(this.format(event));
        });
        return flag;
    }

    format(text: string): string {
        return text?.trim()?.replace(/\s/g, '')?.toLowerCase();
    }
}
