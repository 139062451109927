import { createAction, props } from '@ngrx/store';
import { types } from './types';
import { FlightModel } from '@app/models/flight';

// Content
export const getContent = createAction(types.GET_CONTENT_ATTEMPT);
export const getContentSuccess = createAction(
    types.GET_CONTENT_SUCCESS,
    props<{ payload: any }>()
);
export const getContentFailed = createAction(types.GET_CONTENT_FAILED);

export const setIsGetContent = createAction(
    types.SET_IS_GET_CONTENT,
    props<{ payload: any }>()
);

// Flights
export const getFlights = createAction(types.GET_FLIGHTS_ATTEMPT);

export const getFlightsSuccess = createAction(
    types.GET_FLIGHTS_SUCCESS,
    props<{
        payload: {
            flights: FlightModel[];
            airlinesCodes: Array<{ key: string }>;
        };
    }>()
);
export const getFlightsFailed = createAction(types.GET_FLIGHTS_FAILED);

// Weather Information
export const getWeatherInformation = createAction(types.GET_WEATHER);
export const getWeatherInformationSuccess = createAction(
    types.GET_WEATHER_SUCCESS,
    props<{ payload: any }>()
);
export const getWeatherInformationFailed = createAction(
    types.GET_WEATHER_FAILED
);

// Set POI Walk time
export const setPOIWalkTime = createAction(
    types.SET_POI_WALK_TIME,
    props<{ payload: any }>()
);

export const changeLanguage = createAction(
    types.CHANGE_LANGUAGE,
    props<{ payload: string }>()
);

export const setImages = createAction(
    types.SET_IMAGES,
    props<{ payload: any }>()
);

export const setKioskLocation = createAction(
    types.SET_KIOSK_LOCATION,
    props<{ payload: any }>()
);

export const setPinCoordinates = createAction(
    types.SET_PIN_COORDINATES,
    props<{ position: any; floor?: string }>()
);
