import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from '@app/microsite/components/list/list.component';
import { EmptyStateComponent } from '@app/microsite/components/empty-state/empty-state.component';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ListItemComponent } from '@app/microsite/components/list-item/list-item.component';
import { FlightComponent } from '@app/microsite/components/flight/flight.component';
import { TextInputComponent } from '@app/microsite/components/text-input/text-input.component';
import { FilterFlightsPipe } from '@app/microsite/pipes/filter-flights/filter-flights.pipe';
import { RatingComponent } from '@app/microsite/components/rating/rating.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ListComponent,
        RatingComponent,
        ListItemComponent,
        EmptyStateComponent,
        FlightComponent,
        TextInputComponent,
        FilterFlightsPipe,
    ],
    imports: [CommonModule, SharedModule, TranslateModule, FormsModule],
    exports: [
        ListComponent,
        ListItemComponent,
        EmptyStateComponent,
        FlightComponent,
        TextInputComponent,
        FilterFlightsPipe,
    ],
})
export class CoreModule {}
