import { FlightPartnerModel } from '@app/models/flight';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-flight-icon',
    templateUrl: './flight-icon.component.html',
    styleUrls: ['./flight-icon.component.scss'],
})
export class FlightIconComponent {
    @Input() props: FlightPartnerModel;
    @Input() type: string;
}
