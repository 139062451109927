import { NavigationEnd, Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { timer } from 'rxjs';
import {
    EventEmitter,
    TemplateRef,
    Renderer2,
    Component,
    ViewChild,
    OnInit,
    Output,
    Input,
} from '@angular/core';

@Component({
    selector: 'app-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.scss'],
})
export class BottomSheetComponent implements OnInit {
    @ViewChild('modalTemplate') modalTemplate: TemplateRef<any>;

    @Output() closeBottomSheet = new EventEmitter();
    @Input() isCollapsedMode = false;
    @Input() poiImage: string;

    panelClassName = 'bottom-sheet';
    modalTemplateRef: any;

    constructor(
        private overlay: OverlayContainer,
        public renderer: Renderer2,
        public dialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit(): void {
        if (this.isCollapsedMode) {
            this.panelClassName = 'collapsed-sheet';
        }

        this.modalTemplateRef?.addPanelClass(this.panelClassName);

        this.router.events.subscribe((routerEvent) => {
            if (routerEvent instanceof NavigationEnd) {
                if (!this.router.url.includes('home') && this.isCollapsedMode) {
                    this.modalTemplateRef.close();
                    this.closeBottomSheet.emit();
                }
            }
        });
    }

    open(): void {
        this.disableAnimations();
        this.modalTemplateRef = this.dialog.open(this.modalTemplate, {
            hasBackdrop: !this.isCollapsedMode,
            disableClose: true,
            panelClass: [this.panelClassName],
        });

        this.modalTemplateRef.backdropClick().subscribe(async () => {
            this.close();
        });
    }

    disableAnimations(): void {
        this.renderer.setProperty(
            this.overlay.getContainerElement(),
            '@.disabled',
            true
        );
    }

    async enableAnimations(): Promise<void> {
        await new Promise<void>((res) => {
            timer(500).subscribe(() => {
                this.renderer.setProperty(
                    this.overlay.getContainerElement(),
                    '@.disabled',
                    false
                );
                res();
            });
        });
    }

    async close(direction = ''): Promise<void> {
        if (
            (direction === 'up' && !this.isCollapsedMode) ||
            (this.isCollapsedMode && direction !== 'up')
        ) {
            return;
        }

        this.modalTemplateRef.addPanelClass(`${this.panelClassName}-close`);

        if (this.isCollapsedMode) {
            // timeout to wait for animation to close collapsed view  before navigation
            setTimeout(() => {
                this.router.navigate(['microsite/categories'])?.then(() => {
                    setTimeout(() => {
                        this.modalTemplateRef.close();
                        this.closeBottomSheet.emit();
                    }, 300);
                });
            }, 500);
        } else {
            await new Promise<void>((res) => {
                timer(500).subscribe(() => {
                    this.modalTemplateRef.close();
                    this.closeBottomSheet.emit();

                    res();
                });
            });
        }

        await this.enableAnimations();
    }
}
