import { TranslateService } from '@ngx-translate/core';
import { selectDataBus } from '@app/store/selectors';
import { AppConfig } from 'environments/environment';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-weather',
    templateUrl: './weather.component.html',
    styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent {
    appType = AppConfig.appType;
    weatherImage: string;
    temperature: string;
    tempValue = null;
    mode = 'F';

    constructor(private translate: TranslateService, private store: Store) {
        const dataBusSelector = this.store.select(selectDataBus);

        dataBusSelector.subscribe((dataBusItem) => {
            this.tempValue = dataBusItem.weather?.temperature || 'N/A';
            const weatherImage = dataBusItem.weather?.weatherImage;
            this.weatherImage = weatherImage
                ? this.setWeatherIcon(
                      weatherImage.mode ? weatherImage.mode : 'day',
                      weatherImage.key
                  )
                : '';
            this.setTemperature();
        });

        setInterval(() => {
            this.mode = this.mode === 'F' ? 'C' : 'F';
            this.setTemperature();
        }, 5000);
    }

    setTemperature(): void {
        if (this.tempValue === 'N/A') {
            this.temperature = this.translate.instant('WEATHER.N/A');
        } else {
            if (this.mode === 'F') {
                this.temperature = this.tempValue + '°F';
            } else {
                this.temperature =
                    Math.round((this.tempValue - 32) * (5 / 9)) + '°C';
            }
        }
    }

    setWeatherIcon(mode: string, key: string): string {
        let iconKey = 'ovc';
        switch (key) {
            case 'wind_skc':
            case 'wind_few':
            case 'wind_sct':
            case 'wind_bkn':
            case 'wind_ovc':
                iconKey = 'wind';
                break;

            case 'rain_snow':
            case 'rain_sleet':
            case 'snow_sleet':
            case 'sleet':
                iconKey = 'rain_snow';
                break;

            case 'fzra':
            case 'rain_fzra':
            case 'snow_fzra':
                iconKey = 'fzra';
                break;

            case 'rain':
            case 'rain_showers':
            case 'rain_showers_hi':
                iconKey = 'rain';
                break;

            case 'tsra':
            case 'tsra_sct':
            case 'tsra_hi':
                iconKey = 'tsra';
                break;

            case 'hurricane':
            case 'tropical_storm':
                iconKey = 'hurricane';
                break;

            case 'haze':
            case 'fog':
                iconKey = 'fog';
                break;

            default:
                if (
                    [
                        'skc',
                        'few',
                        'sct',
                        'bkn',
                        'ovs',
                        'snow',
                        'hot',
                        'cold',
                        'blizzard',
                    ].find((item) => item === key)
                ) {
                    iconKey = key;
                }
                break;
        }

        return `assets/weather/${mode}/${iconKey}.png`;
    }
}
