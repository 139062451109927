import { PoiListModel } from '@app/models/poi-list';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-poi-list',
    templateUrl: './poi-list.component.html',
    styleUrls: ['./poi-list.component.scss'],
})
export class PoiListComponent {
    @Input() props: PoiListModel;
}
