import { DynamicDataModel } from '@app/models/content-data';
import {
    ComponentFactoryResolver,
    ViewContainerRef,
    ComponentRef,
    EventEmitter,
    Directive,
    OnInit,
    Output,
    Input,
    OnChanges,
} from '@angular/core';

@Directive({
    selector: '[dynamicView]',
})
export class DynamicDetailsDirective implements OnInit, OnChanges {
    @Output() changeEvent = new EventEmitter();

    @Input() config: DynamicDataModel;
    component: ComponentRef<any>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        public viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this.initializeComponentProps();
    }

    ngOnChanges(changes) {
        this.config = changes.config.currentValue;
        this.component = null;
        this.initializeComponentProps();
    }

    initializeComponentProps(): void {
        if (!this.component) {
            const componentFactory =
                this.componentFactoryResolver.resolveComponentFactory(
                    this.config.component
                );

            this.viewContainerRef.clear();

            this.component =
                this.viewContainerRef.createComponent(componentFactory);
        }
        const instance = this.component.instance;

        instance.props = this.config.props;
        instance.changeEvent?.subscribe((res) => {
            this.changeEvent.emit(res);
        });
    }
}
