import { UtilityService } from '@app/services/utility/utility.service';
import { ContentModel } from '@app/models/content-data';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-advertisement',
    templateUrl: './advertisement.component.html',
    styleUrls: ['./advertisement.component.scss'],
})
export class AdvertisementComponent {
    @Input() props: ContentModel = new ContentModel();

    constructor(public utilityService: UtilityService) {}
}
