import { IconModel } from './icon';

export class EmptyStateModel {
    title: string;
    subtitle?: string;
    icon?: IconModel;
    containerStyle?: any;
    constructor(props?: EmptyStateModel) {
        return {
            icon: props.icon,
            title: props.title,
            subtitle: props.subtitle,
            containerStyle: props.containerStyle,
        };
    }
}
