export interface MapPois {
    position?: Position;
    floor?: string;
    selector?: string;
    iconPath?: string;
    mapElementId?: string;
    name?: string;
}

export interface Position {
    x?: number;
    y?: number;
    z?: number;
}

export class WalkTimeModel {
    walkTime?: any;

    constructor(props: WalkTimeModel) {
        return {
            walkTime:
                props.walkTime && props.walkTime.length > 0
                    ? Math.ceil(props.walkTime[0].totalTime / 60)
                    : 0,
        };
    }
}

export interface IFloor {
    id: string;
    shortName?: string;
    name?: string;
    isDefault?: boolean;
    isSelected?: boolean;
    isDisabled?: boolean;
}
