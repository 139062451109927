import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { IconModel } from '@app/models/icon';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit, OnChanges {
    @Input() imageFilterClassName: { [key: string]: boolean };
    @Input() props: IconModel;

    safeUrl: SafeResourceUrl;
    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.setIcon();
    }

    ngOnChanges(): void {
        this.setIcon();
    }

    setIcon(): void {
        this.props = new IconModel(this.props);
        this.iconRegistry.addSvgIcon(
            this.props.icon,
            this.sanitizer.bypassSecurityTrustResourceUrl(
                `assets/material-icons/${this.props.icon}.svg`
            )
        );
    }
}
