import { ContentModel } from '@app/models/store/content';
import { AppConfig } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { FlightModel } from '@app/models/flight';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient) {}

    getWeatherInformation(): Observable<any> {
        return this.http.get(`${AppConfig.weather.API}`).pipe(retry(1));
    }

    getContent(): Observable<{ data: ContentModel; version?: string }> {
        return this.http.get<{ data: ContentModel }>(
            `${AppConfig.endpoints.url}/contents/getContents`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${AppConfig.endpoints.apiKey}`,
                },
            }
        );
    }

    getFlights(): Observable<{ flights: FlightModel[] }> {
        return this.http.get<{ flights: FlightModel[] }>(
            `${AppConfig.endpoints.url}/getFlights`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${AppConfig.endpoints.apiKey}`,
                },
            }
        );
    }
}
