import { IconModel } from './icon';

export class ButtonModel {
    id?: string;
    icon?: IconModel;
    text?: string | { en?: string; es?: string };
    disabled?: boolean;
    toggle?: boolean;
    toggleIcon?: IconModel;
    toggleText?: string;
    containerStyle?: any;
    containerIconStyle?: any;
    customContainerClasses?: { [key: string]: boolean };

    constructor(props?: ButtonModel) {
        return {
            id: props.id,
            icon: props.icon,
            text: props.text,
            disabled: props.disabled || false,
            toggle: props.toggle || false,
            toggleIcon: props.toggleIcon,
            toggleText: props.toggleText,
            containerStyle: props.containerStyle,
            containerIconStyle: props.containerIconStyle,
            customContainerClasses: props.customContainerClasses,
        };
    }
}
