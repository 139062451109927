import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { animationConfig } from '@app/animations/animation';
import { ElectronService } from '@app/core/services';
import { AppConfig } from 'environments/environment';
import { selectDataBus } from '@app/store/selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-qr',
    templateUrl: './qr.component.html',
    styleUrls: ['./qr.component.scss'],
    animations: [animationConfig.shimmerAnimation],
})
export class QrComponent implements OnInit, OnDestroy {
    qrWidth = window.innerWidth < 2160 ? 100 : 430;
    animationInterval: any;
    dataBusSubscription: Subscription;
    animState = false;
    value: string;

    constructor(public electron: ElectronService, private store: Store) {
        let mapElementID: string = AppConfig.defaultKioskLocation;

        const dataBusSelector = this.store.select(selectDataBus);

        this.dataBusSubscription = dataBusSelector.subscribe((res) => {
            if (this.electron.isElectron) {
                const computerName: string = this.electron.getComputerName();
                res.content.kioskNames?.forEach((item) => {
                    if (item.computerName === computerName) {
                        mapElementID = item.poiID;
                    }
                });
            }

            this.value = AppConfig.micrositeUrl.concat(`?id=${mapElementID}`);
        });
    }

    ngOnInit(): void {
        clearInterval(this.animationInterval);
        this.animationInterval = setInterval(() => {
            this.animState = !this.animState;
        }, 2000);
    }

    ngOnDestroy(): void {
        clearInterval(this.animationInterval);
        this.dataBusSubscription.unsubscribe();
    }

    @HostListener('window:resize')
    onResize(): void {
        this.qrWidth = window.innerWidth < 2160 ? 100 : 430;
    }
}
