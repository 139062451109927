export const pageContentDummyDescription = `<p>Some text here to display</p><p><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.&nbsp;</strong></p><p>&nbsp;</p><h4><u><span style="background-color:#2ecc71">More text here</span></u></h4><p><em><span style="font-family:Comic Sans MS,cursive">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.&nbsp;</span></em></p>`;

export const emergencyContentDummyData = {
    title: { en: 'Emergency!' },
    subtitle: {
        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
    },
    enabled: true,
};

export const poiList = [
    {
        title: 'Food & beverage',
        list: [
            {
                title: 'Lower Level',
                items: [{ floorNb: 'L1', name: 'Carlos & mickeys' }],
            },
            {
                title: 'Upper Level',
                items: [
                    { floorNb: 'U1', name: 'Starbucks' },
                    { floorNb: 'U3', name: 'PGA Tour Grill' },
                    { floorNb: 'U8', name: 'Home Team Sports' },
                    { floorNb: 'U14', name: 'Starbucks' },
                    { floorNb: 'U15', name: 'Cinnabon' },
                    { floorNb: 'U18', name: 'Beer Cart' },
                ],
            },
        ],
        color: 'var(--primary-bg-color)',
    },
    {
        title: 'shopping',
        list: [
            {
                title: 'Lower Level',
                items: [
                    {
                        floorNb: 'L14',
                        name: 'Information desk/Visit El Paso Shop',
                    },
                    { floorNb: 'L21', name: 'Plaza News' },
                ],
            },
            {
                title: 'Upper Level',
                items: [
                    { floorNb: 'U2', name: 'El paso Marketplace' },
                    { floorNb: 'U5', name: 'iStore' },
                    { floorNb: 'U6', name: 'Benefit cosmetics kiosk' },
                ],
            },
        ],
        color: 'var(--dark-blue)',
    },
];
