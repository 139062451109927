/* eslint-disable @ngrx/no-store-subscription */
/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { MapWrapperService } from '@app/services/map-wrapper/map-wrapper.service';
import { UtilityService } from '@app/services/utility/utility.service';
import { selectDataBus } from '@app/store/selectors';
import { AppConfig } from 'environments/environment';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    getWeatherInformation,
    setIsGetContent,
    getContent,
    getFlights,
} from '@app/store/actions';
import _ from 'lodash';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    intervalGetContent: any;
    getContentCompleted = false;
    timeout: NodeJS.Timeout;
    dataImages = [];

    constructor(
        public mapWrapperService: MapWrapperService,
        private utilityService: UtilityService,
        public store: Store
    ) {}

    ngOnInit(): void {
        this.store.dispatch(setIsGetContent({ payload: false }));
        this.store.dispatch(getWeatherInformation());
        this.store.dispatch(getContent());
        let contentData = {};
        let flights = [];

        if (AppConfig.appType === 'microsite') {
            this.store.dispatch(getFlights());
        }

        let data = null;
        this.store.select(selectDataBus).subscribe((res) => {
            if (res?.content) {
                if (
                    !_.isEqual(
                        _.omit(res.content, 'lastUpdated', 'isGetContent'),
                        _.omit(contentData, 'lastUpdated', 'isGetContent')
                    ) ||
                    !_.isEqual(res.flights, flights)
                ) {
                    contentData = res.content;
                    flights = res.flights;
                    this.utilityService.preload(res);

                    this.getContentCompleted = true;
                }
            }

            if (res?.content?.isGetContent) {
                clearTimeout(this.timeout);
                if (
                    !_.isEqual(
                        _.omit(
                            res,
                            'weather',
                            'content',
                            'mapCategories',
                            'language',
                            'kioskLocation',
                            'pinCoordinates',
                            'kioskFloor'
                        ),
                        _.omit(
                            data,
                            'weather',
                            'content',
                            'mapCategories',
                            'language',
                            'kioskLocation',
                            'pinCoordinates',
                            'kioskFloor'
                        )
                    ) &&
                    res.images
                ) {
                    this.timeout = setTimeout(() => {
                        data = res;

                        this.dataImages = [];
                        Object.values(res.images).forEach((item) => {
                            let tmp = Object.values(item);

                            let imagesArray = [];
                            if (item['smallSize']) {
                                tmp.forEach((images) => {
                                    imagesArray = _.isPlainObject(images)
                                        ? imagesArray.concat(
                                              Object.values(images)
                                          )
                                        : imagesArray;
                                });
                            } else {
                                imagesArray = tmp;
                            }

                            tmp = imagesArray;

                            this.dataImages = this.dataImages.concat(tmp);
                        });
                    }, 500);
                }
            }
        });

        this.utilityService.setSiteName();

        setInterval(() => {
            this.store.dispatch(getContent());
        }, AppConfig.getContentInterval);
        setInterval(() => {
            this.store.dispatch(getWeatherInformation());
        }, AppConfig.weather.refreshInterval);

        // if (AppConfig.appType === 'microsite') {
        //     this.intervalGetContent = setInterval(() => {
        //         if (
        //             this.getContentCompleted &&
        //             this.mapWrapperService.mapInitialized
        //         ) {
        //             const flightData = [];

        //             const gates = this.utilityService.mapElementIDs.filter(
        //                 (item) => item.toLowerCase().includes('gate')
        //             );

        //             this.utilityService.flights?.forEach((flight) => {
        //                 const index = gates.findIndex((gate) =>
        //                     gate.includes(flight.gate)
        //                 );

        //                 if (index !== -1) {
        //                     flightData.push({
        //                         ...flight,
        //                         mapElementID: gates[index],
        //                     });
        //                 }
        //             });

        //             this.mapWrapperService.calculateWalkTime(
        //                 this.utilityService.pois.concat(flightData),
        //                 this.utilityService.kioskLocation
        //             );

        //             clearInterval(this.intervalGetContent);
        //         }
        //     }, 1000);
        // }
    }
}
