import { MapWrapperService } from '@app/services/map-wrapper/map-wrapper.service';
import { UtilityService } from '@app/services/utility/utility.service';
import { setKioskLocation } from '@app/store/actions';
import { AppConfig } from 'environments/environment';
import { ElectronService } from '@app/core/services';
import { poiList } from '@app/mock/kiosk-home-mock';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    ChangeDetectorRef,
    HostListener,
    ElementRef,
    Component,
    OnDestroy,
    ViewChild,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
    @ViewChild('map', { read: ElementRef, static: true }) map: ElementRef;

    timeout: NodeJS.Timeout;
    isMapHidden = false;
    poiList = poiList;
    kioskID: string;
    type: string;

    constructor(
        public mapWrapperService: MapWrapperService,
        private utilityService: UtilityService,
        public electron: ElectronService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        private store: Store
    ) {
        this.type = AppConfig.appType;

        this.utilityService.triggerFloorChange.subscribe((event) => {
            if (this.mapWrapperService.showIndicator) {
                this.onFloorChange(event);
            }
        });
    }

    ngOnInit(): void {
        this.initializeMap();
    }

    initializeMap(): void {
        this.isMapHidden = true;
        setTimeout(() => {
            this.isMapHidden = false;

            setTimeout(() => {
                this.mapWrapperService
                    .initializeMap(this.map.nativeElement)
                    .subscribe(() => {
                        this.mapWrapperService.setUpMap();

                        setTimeout(() => {
                            const kioskID =
                                this.getKioskID() ||
                                this.utilityService.kioskLocation;

                            this.mapWrapperService.mapInitialized = true;
                            if (kioskID) {
                                this.kioskID = kioskID;

                                setTimeout(() => {
                                    this.mapWrapperService.setIndicatorForID(
                                        kioskID
                                    );
                                    setTimeout(() => {
                                        if (
                                            this.mapWrapperService.showIndicator
                                        ) {
                                            if (
                                                this.utilityService.kioskFloor
                                            ) {
                                                this.onFloorChange(
                                                    this.utilityService
                                                        .kioskFloor || 'B1-UL00'
                                                );
                                            }
                                            this.mapWrapperService.setMapPosition(
                                                true
                                            );
                                        }
                                    }, 100);
                                }, 300);
                            }
                        }, 200);
                    });
            }, 100);
        }, 200);
    }

    ngOnDestroy(): void {
        this.mapWrapperService.vg.stop();
        this.mapWrapperService.vg.unloadMap();
    }

    onFloorChange(event: any): void {
        const selectedFloor = this.mapWrapperService.floors.find(
            (floor) => floor.id === event
        );

        if (selectedFloor?.isDisabled) {
            return;
        }

        this.mapWrapperService.floors = this.mapWrapperService.floors.map(
            (item) => {
                if (item.id === event) {
                    item.isSelected = true;
                } else {
                    item.isSelected = false;
                }
                return item;
            }
        );

        if (this.mapWrapperService.selectedFloor !== event) {
            this.mapWrapperService.selectedFloor = event;

            this.mapWrapperService.changeFloor(event);
        }
    }

    getKioskID(): string {
        let kioskLocation = '';
        switch (this.type) {
            case 'microsite':
                kioskLocation = this.router.url.split('=')[1];
                break;
            case 'web':
                kioskLocation = !this.electron.isElectron
                    ? AppConfig.defaultKioskLocation
                    : AppConfig.production
                    ? this.electron.getComputerName()
                    : AppConfig.defaultKioskLocation;
                break;
        }
        this.store.dispatch(setKioskLocation({ payload: kioskLocation }));
        return kioskLocation || this.utilityService.kioskLocation;
    }

    @HostListener('window:orientationchange', ['$event'])
    @HostListener('window:resize')
    onResize(): void {
        this.mapWrapperService.resize();
        this.cdr.detectChanges();
    }
}
