import { IFloor, WalkTimeModel } from '@app/models/services/mapWrapper';
import { setPinCoordinates, setPOIWalkTime } from '@app/store/actions';
import { UtilityService } from '../utility/utility.service';
import { PoiModel } from '@app/models/store/content/poi';
import { AppConfig } from 'environments/environment';
import { Injectable, Inject } from '@angular/core';
import { Observable, from, retry } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root',
})
export class MapWrapperService {
    public floors: IFloor[] = [];
    public selectedFloor = null;
    public vg: any;

    defaultBuilding: any;
    promise: any;
    mapData: any;

    poisWalkTime: { [key: string]: WalkTimeModel };
    mapInitialized = false;
    showIndicator: boolean;
    computerName: string;
    currentFloor: string;
    kioskInfo: any;

    constructor(
        @Inject('mapFactory') private mapFactory: any,
        private utility: UtilityService,
        public store: Store
    ) {
        this.vg = this.mapFactory;
    }

    public getMapViewer(): any {
        return this.vg;
    }

    public initializeMap(element: any): Observable<any> {
        this.promise = this.vg
            .initialize(element, {
                path: 'assets/map/offline/descriptor.json',
            })
            .then(() => {
                this.vg.start();

                this.setupFloors();
                this.removeKIOSKPois();
            })
            .then(() => {
                this.setMapPosition();
            });

        return from(this.promise);
    }

    setMapPosition(renderCameraAccToCoord: boolean = false): void {
        let position = null;

        if (AppConfig.appType === 'microsite') {
            position = {
                x: -20,
                y: -40,
                radius: 230,
            };

            if (
                this.utility.pinCoordinates &&
                renderCameraAccToCoord &&
                this.showIndicator
            ) {
                position = { ...this.utility.pinCoordinates, radius: 230 };
            }
        } else {
            position = {
                x: 50,
                y: 20,
                radius: 520,
            };
        }

        if (
            position.x !== this.vg.camera.position.x &&
            position.y !== this.vg.camera.position.y
        ) {
            this.disableCameraPanningTimeout();
            this.vg.camera.goTo(position, '2000');
        }
    }

    public resize(): void {
        this.vg.resize(
            window.innerWidth,
            AppConfig.appType !== 'microsite' && window.innerWidth > 2159
                ? window.innerHeight / 2
                : window.innerHeight
        );

        if (AppConfig.appType === 'microsite') {
            this.vg.camera.minRadius = 150;
            this.vg.camera.maxRadius = 400;

            const boundValue =
                window.innerWidth > window.innerHeight
                    ? window.innerHeight
                    : window.innerWidth;

            this.vg.camera.setBoundary([
                {
                    x: -boundValue / 2,
                    y: -boundValue / 2,
                },
                {
                    x: boundValue / 2,
                    y: -boundValue / 2,
                },
                {
                    x: boundValue / 2,
                    y: boundValue / 2,
                },
                {
                    x: -boundValue / 2,
                    y: boundValue / 2,
                },
                {
                    x: -boundValue / 2,
                    y: -boundValue / 2,
                },
            ]);
        }
    }

    changeFloor(id: string): void {
        this.currentFloor = id;

        this.vg.changeFloor(id, { animationDuration: 300 });

        this.setMapPosition();

        setTimeout(() => {
            this.floors = this.floors.map((floor) => {
                if (floor.id === id) {
                    floor.isDisabled = true;
                } else {
                    floor.isDisabled = false;
                }
                return floor;
            });
        }, 700);
    }

    public getFloorName(floorId: string): string {
        return this.mapData.resources.en.localized.locale.en.venueLayout[
            floorId
        ].name;
    }

    public getFloorShortName(floorId: string): string {
        return this.mapData.resources.en.localized.locale.en.venueLayout[
            floorId
        ].shortName;
    }

    public setUpMap(): void {
        // we can manipulate the pitch current/original -56.14
        // this.vg.camera.pitch = false;
        this.vg.camera.pitch = -120;

        this.vg.camera.pitchManipulatorEnabled = false;
        this.vg.camera.rotationManipulatorEnabled = false;

        if (AppConfig.appType === 'microsite') {
            this.vg.camera.panManipulatorEnabled = true;
            this.vg.camera.zoomManipulatorEnabled = true;
            this.vg.camera.zoomInertiaEnabled = false;
            this.vg.camera.minRadius = 100;
            this.vg.camera.maxRadius = 400;
        } else {
            this.vg.camera.panManipulatorEnabled = false;
            this.vg.camera.zoomManipulatorEnabled = false;
        }

        this.resize();
    }

    disableCameraPanningTimeout(): void {
        this.vg.camera.panManipulatorEnabled = false;

        setTimeout(() => {
            this.vg.camera.panManipulatorEnabled = true;
        }, 700);
    }

    setupFloors(): void {
        this.mapData = this.vg.getExtraData();

        this.defaultBuilding = this.getDefaultBuilding();

        this.mapFloors();
    }

    getDefaultBuilding(): any {
        return this.mapData.config.venue_layout.buildings[
            this.mapData.config.venue_layout.defaultBuilding
        ];
    }

    mapFloors(): void {
        this.floors = [];

        const defaultFloor =
            this.selectedFloor || this.defaultBuilding.defaultFloor;

        Object.keys(this.defaultBuilding.floors).forEach((floorKey) => {
            const mappedFloor: IFloor = {
                id: floorKey,
                shortName:
                    this.mapData.resources.en.localized.locale.en.venueLayout[
                        floorKey
                    ].shortName,
                name: this.mapData.resources.en.localized.locale.en.venueLayout[
                    floorKey
                ].name,
                isDefault: false,
                isSelected: false,
                isDisabled: false,
            };

            if (mappedFloor.id === defaultFloor) {
                mappedFloor.isDefault = true;
                mappedFloor.isSelected = true;
                mappedFloor.isDisabled = true;
            }
            if (
                (AppConfig.appType === 'microsite' &&
                    mappedFloor.id !== 'B1-UL02') ||
                AppConfig.appType === 'web' ||
                AppConfig.appType === 'electron'
            ) {
                this.floors.push(mappedFloor);
            }
        });

        this.floors.reverse();

        this.changeFloor(defaultFloor);

        if (AppConfig.appType === 'web' || AppConfig.appType === 'electron') {
            this.changeFloor('B1-UL02');
        }
    }

    computeRoute(source: string, destination: string): Promise<any> {
        return new Promise((resolve) => {
            resolve(
                this.vg.computeRoute({
                    src: source,
                    dst: destination,
                    computeNavigation: true,
                    routingParameters: {
                        requestType: 'shortest',
                    },
                    navigationParameters: {
                        firstNodeAsIntersection: true,
                        modalityParameters: {
                            pedestrian: {
                                straightAngleThreshold: 45,
                                minimumInstructionLength: 1,
                                nearPlacesThreshold: 300,
                            },
                        },
                    },
                })
            );
        });
    }

    calculateWalkTime(pois: PoiModel[], kioskID: string): void {
        this.kioskInfo = {
            map_element_id: kioskID,
        };

        this.poisWalkTime = {};
        this.setWalkTime(pois, -1);
    }

    setWalkTime(
        pois: PoiModel[],
        index?: number,
        returnValue?: WalkTimeModel
    ): void {
        if (returnValue) {
            this.poisWalkTime[pois[index].mapElementID] = returnValue;
        }

        if (pois.length > index + 1) {
            this.getWalkTimeDuration(pois, index + 1);
        } else {
            this.store.dispatch(setPOIWalkTime({ payload: this.poisWalkTime }));
        }
    }

    getWalkTimeDuration(pois: PoiModel[], i: number): void {
        this.computeRoute(this.kioskInfo.map_element_id, pois[i].mapElementID)
            .then((route: any) => {
                let returnValue = null;
                if (!route.data.error) {
                    returnValue = new WalkTimeModel({
                        walkTime: route.data.navigation.instructions,
                    });
                }

                this.setWalkTime(pois, i, returnValue);
            })
            .catch(() => {
                this.setWalkTime(pois, i, null);
            });
    }

    setIndicatorForID(poiID: string): void {
        if (poiID !== '' && this.vg) {
            const poi = this.vg.getPOI(poiID)?.[0]?.options();
            if (!poi) {
                this.showIndicator = false;
                return;
            }
            this.showIndicator = true;
            setTimeout(() => {
                this.vg.addPOI({
                    floor: poi?.floor,
                    selector: '#indicator',
                    id: 'INDICATOR',
                    position: poi?.position,
                    scale: 15.0,
                    overlay: true,
                    zoomScaleFactor: 4,
                });
                this.store.dispatch(
                    setPinCoordinates({
                        position: poi?.position,
                        floor: poi?.floor,
                    })
                );
            }, 100);
        }
    }

    removeKIOSKPois(): void {
        const visioPOIs = this.vg.getPOIs();
        for (const i in visioPOIs) {
            if (Object.prototype.hasOwnProperty.call(visioPOIs, i)) {
                const poi = visioPOIs[i][0];
                if (i.includes('KIOSK')) {
                    poi.remove();
                    continue;
                }
            }
        }
    }
}
