import { ContentModel } from '@app/models/content-data';
import {
    EventEmitter,
    ViewChild,
    Component,
    Output,
    Input,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
    @ViewChild('youtubePlayer') youtubePlayer;

    @Output() changeEvent = new EventEmitter<any>();
    @Input() props: ContentModel = new ContentModel();

    currentOrigin = window.location.origin;

    ngOnInit(): void {
        this.currentOrigin = window.location.origin;
    }

    /* API will call this function when Player State changes like PLAYING, PAUSED, ENDED */
    onPlayerStateChange(event: any): any {
        //event.data=0 = video Ended
        //event.data=1 = video Start
        //event.data=2 = video Paused
        if (event.data === 0 || event.data === 2) {
            console.log('emit', event);

            this.changeEvent.emit(event);
        }
        // } else if (event.data === -1 && event.target) {
        //     setTimeout(() => {
        //         event.target.playVideo();
        //     }, 1500);
        // }
    }
}
