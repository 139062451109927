import { UtilityService } from '@app/services/utility/utility.service';
import { NavigationEnd, Router } from '@angular/router';
import { ButtonModel } from '@app/models/button';
import {
    ChangeDetectorRef,
    EventEmitter,
    Component,
    OnChanges,
    Output,
    OnInit,
    Input,
} from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges {
    @Output() clicked = new EventEmitter();

    @Input() props: ButtonModel;

    checked: boolean;

    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef,
        public utilityService: UtilityService
    ) {}

    ngOnInit(): void {
        if (this.props.id === 'CATEGORY') {
            this.checked = this.router.url.includes('categories');
            this.router.events.subscribe((routerEvent) => {
                if (routerEvent instanceof NavigationEnd) {
                    this.checked = routerEvent.url.includes('categories');
                    this.cdr.detectChanges();
                }
            });
        }
        if (this.props.id === 'LANGUAGE') {
            this.checked = this.utilityService.selectedLanguage === 'en';
        }
    }

    ngOnChanges(): void {
        this.props = new ButtonModel(this.props);
    }

    onClick(toggle?: boolean): void {
        this.clicked.emit({
            toggle,
            id: this.props.id,
            text: toggle ? this.props?.text : this.props?.toggleText,
        });
    }
}
