import { TimeConversionService } from '@app/services/time-conversion/time-conversion.service';
import { REVISION, VERSION } from 'environments/version';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { selectDataBus } from '@app/store/selectors';
import { AppConfig } from 'environments/environment';
import { HeaderModel } from '@app/models/header';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    EventEmitter,
    Component,
    OnChanges,
    Output,
    Input,
    OnDestroy,
} from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnChanges, OnDestroy {
    @Output() actionClicked = new EventEmitter();

    @Input() props: HeaderModel;

    appType = AppConfig.appType;

    currentYear: string =
        this.timeConversion.formatDate('YYYY', new Date(), true) === '2022'
            ? '2022'
            : `2022${this.timeConversion.formatDate(
                  '-YYYY',
                  new Date(),
                  true
              )}`;
    title = ` v${VERSION} (${REVISION})`;
    subTitle = `© SITA ${this.currentYear}.`;
    closeModalTimeout: NodeJS.Timeout;
    dataBusSubscription: Subscription;
    env = AppConfig.environment;
    cloudFunctionVersion = '';
    showDialog = false;
    headerFixed = true;
    path = '';

    constructor(
        private timeConversion: TimeConversionService,
        private translate: TranslateService,
        private router: Router,
        private store: Store
    ) {
        const dataBusState$ = this.store.select(selectDataBus);

        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.cloudFunctionVersion = dataBusItem.cfVersion;
        });

        let url = this.router.url.split('/');
        this.headerFixed = url[url.length - 1] === 'home';
        this.path = url[url.length - 1];

        this.router.events.subscribe((routerEvent) => {
            if (routerEvent instanceof NavigationEnd) {
                url = this.router.url.split('/');
                this.headerFixed = url[url.length - 1] === 'home';

                this.path = url[url.length - 1];
            }
        });
    }

    ngOnChanges(): void {
        this.props = new HeaderModel(this.props);
    }

    ngOnDestroy(): void {
        this.dataBusSubscription?.unsubscribe();
        this.clearTimeOut();
    }

    clearTimeOut(): void {
        this.showDialog = false;
        clearTimeout(this.closeModalTimeout);
    }

    actionButtonClicked(event: { toggle?: boolean; id: string }): void {
        this.actionClicked.emit(event);
    }

    showAppInfo(): void {
        this.showDialog = true;
        this.closeModalTimeout = setTimeout(() => {
            this.clearTimeOut();
        }, 5000);
    }
}
