import { LanguagesString } from '../content';

export class EmergencyModel {
    enabled?: boolean;
    title?: LanguagesString;
    subtitle?: LanguagesString;
    isGetContent?: boolean;

    constructor(props?: EmergencyModel) {
        return {
            enabled: props?.enabled || false,
            title: { en: props?.title?.en || '', es: props?.title?.es || '' },
            subtitle: {
                en: props?.subtitle?.en || '',
                es: props?.subtitle?.es || '',
            },
            isGetContent: props?.isGetContent || false,
        };
    }
}
