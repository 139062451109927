import { AttractionModel } from '@app/models/store/content/attraction';
import { EmergencyModel } from '@app/models/store/content/emergency';
import { CategoryModel } from '@app/models/store/content/category';
import { DataBusStateModel } from '@app/models/store/data-bus';
import { KioskModel } from '@app/models/store/content/kiosk';
import { VideoModel } from '@app/models/store/content/video';
import { PageModel } from '@app/models/store/content/page';
import { ContentModel } from '@app/models/store/content';
import { PoiModel } from '@app/models/store/content/poi';
import { StateModel } from '@app/models/store/state';
import { FlightModel } from '@app/models/flight';

export const selectDataBus = (state: StateModel): DataBusStateModel =>
    state.dataBus;

export const selectContent = (state: StateModel): ContentModel =>
    state.dataBus.content;

export const selectFlights = (state: StateModel): FlightModel[] =>
    state.dataBus.flights;

export const selectCategories = (state: StateModel): CategoryModel[] =>
    state.dataBus.content?.categories;

export const selectAttractions = (state: StateModel): AttractionModel[] =>
    state.dataBus.content.attractions;

export const selectEmergency = (state: StateModel): EmergencyModel => {
    return {
        ...state.dataBus.content?.emergency,
        isGetContent: state.dataBus.content?.isGetContent,
    };
};

export const selectHotels = (state: StateModel): AttractionModel[] =>
    state.dataBus.content.hotels;

export const selectKioskNames = (state: StateModel): KioskModel[] =>
    state.dataBus.content.kioskNames;

export const selectPages = (state: StateModel): PageModel[] =>
    state.dataBus.content.pages;

export const selectPois = (state: StateModel): PoiModel[] =>
    state.dataBus.content.pois;

export const selectVideos = (state: StateModel): VideoModel[] =>
    state.dataBus.content?.videos;
